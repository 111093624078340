import React, { useRef } from "react"
import SEO from "../../components/seo"
import { en_description } from "../../components/const"
import Layout from "../../components/layout"
import { graphql, PageProps } from "gatsby"
import { Query } from "../../../types/graphql-types"
import "../../style.css"
import "../../components/Policy/style.css"
import PageTitle from "../../components/utils/PageTitle"
import PolicyMenu from "../../components/Policy/PolicyMenu"
import PolicyContent from "../../components/Policy/PolicyContentType"
import { scrollToElement } from "../../components/Policy/function"
import useCustomScroll from "../../hooks/useCustomScroll"
import {
  createTitleLinkComboList,
  transformPolicyEnData
} from "../../components/Policy/const"

const Policy: React.FC<PageProps<Query>> = ({ data, location }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  useCustomScroll(contentRef)
  const { allMicrocmsPolicyEn } = data
  const allPolicyContents = transformPolicyEnData(allMicrocmsPolicyEn)
  const titleLinkComboList = createTitleLinkComboList(allPolicyContents)

  return (
    <Layout location={location} selectPageName="Policy">
      <SEO title="policy" description={en_description} lang={"en"} />
      <PageTitle title={"Policy"} />
      <div className="policy-container">
        <div className="policy-menu">
          <PolicyMenu
            scrollToElement={(id: string) => scrollToElement(id, contentRef)}
            titleLinkComboList={titleLinkComboList}
          />
        </div>
        <div className="policy-content" ref={contentRef}>
          <PolicyContent lang={"en"} contents={allPolicyContents} />
        </div>
      </div>
    </Layout>
  )
}

export default Policy

export const PolicyIndexQuery = graphql`
  query PolicyEnIndexQuery {
    allMicrocmsPolicyEn {
      edges {
        node {
          contents {
            title
            link_id
            contents {
              content
              content_id
              sub_title
            }
          }
        }
      }
    }
  }
`
